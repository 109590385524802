import Vue from 'vue'
import Card from './Card'
import Child from './Child'
import Button from './Button'
import Checkbox from './Checkbox'
import CarouselCustom from './carousel/CarouselCustom'
import { HasError, AlertError, AlertSuccess } from 'vform'
import VueParticles from 'vue-particles'

// Components that are registered globaly.
[
  Card,
  Child,
  Button,
  CarouselCustom,
  Checkbox,
  HasError,
  AlertError,
  AlertSuccess,

].forEach(Component => {
  Vue.component(Component.name, Component)
})

Vue.use(VueParticles)

