<template>
  <div class="main-layout">
<!--    <navbar />-->
    <div class="container" style="padding-top: 1%; padding-bottom: 1%;">
      <child />
    </div>
  </div>
</template>

<script>
import Navbar from '~/components/Navbar'

export default {
  name: 'MainLayout',

  components: {
    Navbar
  }
}
</script>
