<template>
  <div v-if="timer" class="base-timer">
    <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="base-timer__circle">
        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
        <path
          :stroke-dasharray="circleDasharray"
          class="base-timer__path-remaining"
          :class="remainingPathColor"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        ></path>
      </g>
    </svg>
    <span class="base-timer__label">{{ formattedTimeLeft }}</span>
  </div>
</template>

<script>
    const FULL_DASH_ARRAY = 283;
    const WARNING_THRESHOLD = 10;
    const ALERT_THRESHOLD = 5;

    const COLOR_CODES = {
        info: {
            color: "green"
        },
        warning: {
            color: "orange",
            threshold: WARNING_THRESHOLD
        },
        alert: {
            color: "red",
            threshold: ALERT_THRESHOLD
        }
    };


    export default {

        props: ["timer"],

        data() {

            return {
                // audio: new Audio('/sound/beep-07.mp3'),
                // finish_audio: new Audio('/sound/beep-09.mp3'),
                timePassed: 0,
                timerInterval: null
            };
        },

        computed: {
            circleDasharray() {
                return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
            },

            formattedTimeLeft() {
                const timeLeft = this.timeLeft;
                const minutes = Math.floor(timeLeft / 60);
                let seconds = timeLeft % 60;

                if (seconds < 10) {
                    seconds = `0${seconds}`;
                }

                return `${minutes}:${seconds}`;
            },

            timeLeft() {
                return this.timer - this.timePassed;
            },

            timeFraction() {
                const rawTimeFraction = this.timeLeft / this.timer;
                return rawTimeFraction - (1 / this.timer) * (1 - rawTimeFraction);
            },

            remainingPathColor() {
                const { alert, warning, info } = COLOR_CODES;

                if (this.timeLeft <= alert.threshold) {
                    return alert.color;
                } else if (this.timeLeft <= warning.threshold) {
                    return warning.color;
                } else {
                    return info.color;
                }
            }
        },

        watch: {
            timeLeft(newValue) {
                if (newValue === 0) {
                    this.onTimesUp();
                    // this.finish_audio.play();
                    this.$emit("step-change-from-timer");
                }
            },
            timePassed: {
                handler(value) {

                    if (value < this.timer) {
                        // if (this.timer > 5 && this.timer - this.timePassed < 5){
                        //     this.audio.play();
                        // }
                        setTimeout(() => {
                            this.timePassed++;
                        }, 1000);
                    }
                },
                immediate: true // This ensures the watcher is triggered upon creation
            }

        },

        methods: {
            onTimesUp() {
                clearInterval(this.timerInterval);
            }
        }
    };
</script>

<style scoped lang="scss">
  .base-timer {
    position: absolute;
    right: 37px;
    top: 37px;
    margin: 0 auto;
    width: 37px;
    height: 37px;
    z-index: 1;

    &__svg {
      transform: scaleX(-1);
    }

    &__circle {
      fill: none;
      stroke: none;
    }

    &__path-elapsed {
      stroke-width: 1px;
      stroke: grey;
    }

    &__path-remaining {
      stroke-width: 7px;
      stroke-linecap: round;
      transform: rotate(90deg);
      transform-origin: center;
      transition: 1s linear all;
      fill-rule: nonzero;
      stroke: currentColor;

      &.green {
        color: rgb(65, 184, 131);
      }

      &.orange {
        color: orange;
      }

      &.red {
        color: red;
      }
    }

    &__label {
      position: absolute;
      width: 37px;
      height: 37px;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
    }
  }
</style>
