<template>
  <div class="hooper-wrapper card-carousel-wrapper">
    <hooper :settings="hooperSettings" ref="carousel" @slide="updateCarousel">
      <slide>
        <div v-if="carouselData === 0" class="slider-wrapper">
          <h3 class="text-center pt-3">
            Teraz Vám na 40 sekúnd ukážeme
            skupinu obrázkov a budeme chcieť, aby ste si ich čo najlepšie zapamätali. Následne obrázky zmiznú.
            Vašou úlohou potom bude spomenúť si na čo najviac obrázkov, nájsť ich medzi inými a správne označiť.
          </h3>
          <h4 class="text-center pt-3">
            Pokračujte stlačením tlačítka Ďalej.
          </h4>
        </div>
      </slide>
      <slide>
        <div v-if="carouselData === 1" class="slider-wrapper">
          <div class="image-holder">
            <img style="max-width: 1000px; max-height: unset; min-height: 350px;"
                 src="/public/img/test/1/subtest1/main.jpg"
                 alt="Instruction image">
          </div>
          <BaseTimer v-if="carouselData === 1" :timer="40" @step-change-from-timer="slideNext"/>
        </div>
      </slide>
      <slide>
        <div v-if="carouselData === 2" class="slider-wrapper">
          <div class="image-holder">
            <h3 class="text-center pt-3">
              Teraz Vám budeme postupne ukazovať rôzne obrázky a Vy budete musieť v priebehu 15 sekúnd určiť, či sa
              tento
              obrázok nachádzal medzi obrázkami, ktoré boli ukázané na 1 minútu.
            </h3>
            <h4 class="text-center pt-3">
              Ak ste porozumeli stlačte tlačítko ďalej.
            </h4>
          </div>
        </div>
      </slide>
      <slide>
        <div v-if="carouselData === 3" class="slider-wrapper">
          <div class="images-test-wrapper">

            <div class="item-wrapper" v-for="imageFromSet in loadImagesBatch(0,5)">
              <div class="image-holder maxh">
                <img :src="imageFromSet.imgPath" alt="Test image">
              </div>
              <TestControlsBool :result="imageFromSet.imgCorrectValue" :img="imageFromSet.imgPath"
                                @send-input-data="gatherInputData"></TestControlsBool>
            </div>

          </div>
        </div>
        <BaseTimer v-if="carouselData === 3 && showTimers[0]" :timer="15"
                   @step-change-from-timer="slideNextFromTimer(0)"/>
      </slide>
      <slide>
        <div v-if="carouselData === 4" class="slider-wrapper">
          <div class="images-test-wrapper">

            <div class="item-wrapper" v-for="imageFromSet in loadImagesBatch(5,10)">
              <div class="image-holder maxh">
                <img :src="imageFromSet.imgPath" alt="Test image">
              </div>
              <TestControlsBool :result="imageFromSet.imgCorrectValue" :img="imageFromSet.imgPath"
                                @send-input-data="gatherInputData"></TestControlsBool>
            </div>

          </div>
        </div>
        <BaseTimer v-if="carouselData === 4 && showTimers[1]" :timer="15"
                   @step-change-from-timer="slideNextFromTimer(1)"/>
      </slide>
      <slide>
        <div v-if="carouselData === 5" class="slider-wrapper">

          <div class="images-test-wrapper">

            <div class="item-wrapper" v-for="imageFromSet in loadImagesBatch(10,15)">
              <div class="image-holder maxh">
                <img :src="imageFromSet.imgPath" alt="Test image">
              </div>
              <TestControlsBool :result="imageFromSet.imgCorrectValue" :img="imageFromSet.imgPath"
                                @send-input-data="gatherInputData"></TestControlsBool>
            </div>

          </div>

        </div>
        <BaseTimer v-if="carouselData === 5 && showTimers[2]" :timer="15"
                   @step-change-from-timer="slideNextFromTimer(2)"/>
      </slide>
      <slide>
        <div v-if="carouselData === 6" class="slider-wrapper">

          <div class="images-test-wrapper">

            <div class="item-wrapper" v-for="imageFromSet in loadImagesBatch(15,20)">
              <div class="image-holder maxh">
                <img :src="imageFromSet.imgPath" alt="Test image">
              </div>
              <TestControlsBool :result="imageFromSet.imgCorrectValue" :img="imageFromSet.imgPath"
                                @send-input-data="gatherInputData"></TestControlsBool>
            </div>

          </div>

        </div>
        <BaseTimer v-if="carouselData === 6 && showTimers[3]" :timer="15"
                   @step-change-from-timer="slideNextFromTimer(3)"/>
      </slide>
      <slide>
        <div v-if="carouselData === 7" class="slider-wrapper">

          <div class="images-test-wrapper">

            <div class="item-wrapper" v-for="imageFromSet in loadImagesBatch(20,25)">
              <div class="image-holder maxh">
                <img :src="imageFromSet.imgPath" alt="Test image">
              </div>
              <TestControlsBool :result="imageFromSet.imgCorrectValue" :img="imageFromSet.imgPath"
                                @send-input-data="gatherInputData"></TestControlsBool>
            </div>

          </div>

        </div>
        <BaseTimer v-if="carouselData === 7 && showTimers[4]" :timer="15"
                   @step-change-from-timer="slideNextFromTimer(4)"/>
      </slide>
      <slide>
        <div v-if="carouselData === 8" class="slider-wrapper">

          <div class="images-test-wrapper">

            <div class="item-wrapper" v-for="imageFromSet in loadImagesBatch(25,30)">
              <div class="image-holder maxh">
                <img :src="imageFromSet.imgPath" alt="Test image">
              </div>
              <TestControlsBool :result="imageFromSet.imgCorrectValue" :img="imageFromSet.imgPath"
                                @send-input-data="gatherInputData"></TestControlsBool>
            </div>

          </div>

        </div>
        <BaseTimer v-if="carouselData === 8 && showTimers[5]" :timer="15"
                   @step-change-from-timer="slideNextFromTimer(5)"/>
      </slide>
      <slide>
        <div v-if="carouselData === 9" class="slider-wrapper">
          <h2 class="text-center pt-5">
            Ďakujeme. Pokračujte stlačením tlačítka Ďalej.
          </h2>
        </div>
      </slide>
    </hooper>
    <div class="controls-wrapper">
      <button v-show="showBack()" class="control-btn btn-secondary p-4" @click.prevent="slidePrev"><i
        class="material-icons">keyboard_arrow_left</i></button>
      <!--      <input v-model="carouselData" type="number" min="0" max="5">-->
      <button v-show="showNext()" class="control-btn btn-primary p-4" @click.prevent="slideNext">
        <i class="material-icons">keyboard_arrow_right</i></button>
    </div>
  </div>
</template>


<style scoped>

  @import "../../../sass/custom/carousel.scss";
</style>

<script>
    import {Hooper, Slide} from 'hooper';
    import 'hooper/dist/hooper.css';
    import BaseTimer from "../BaseTimer";
    import TestControlsBool from "../controls/TestControlsBool"

    export default {

        name: 'CarouselCustom',
        template: "#carousel-custom",
        components: {
            Hooper, Slide, BaseTimer, TestControlsBool
        },
        data() {
            return {
                correctImages: 24,
                incorrectImages: 55,
                showTimers: [
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                ],
                userInputs: [],
                inputData: [],
                randomImages: [],
                back: false,
                carouselData: 0,
                carouselItems: 9,
                hooperSettings: {
                    keysControl: false,
                    wheelControl: false,
                    itemsToShow: 1,
                    centerMode: true,
                    autoPlay: false,
                    infiniteScroll: false,
                    shortDrag: false,
                    mouseDrag: false,
                    touchDrag: false,
                },
            }
        },
        created() {
        },
        watch: {
            carouselData() {
                this.$refs.carousel.slideTo(this.carouselData);
            }
        },
        mounted() {
          this.generateImages();
          console.log('generated');
        },
        methods: {
            getRandImage(max) {
                return Math.round(Math.random() * (max - 1) + 1)
            },
            generateImages() {
              let tempArr = []
              let alreadyCorrect = []
              let alreadyIncorrect = []

              // Set two or three correct randomized items, do it 5 times
              for (let i = 0; i < 6; i++){
                if (Math.round(Math.random())){
                  let pattern = [0,0,0,1,1];
                  pattern = pattern.sort(() => Math.random() - 0.5)
                  tempArr = tempArr.concat(pattern)
                } else {
                  let pattern = [0,0,1,1,1];
                  pattern = pattern.sort(() => Math.random() - 0.5)
                  tempArr = tempArr.concat(pattern)
                }

              }

              for (let i = 0; i < tempArr.length; i++) {
                if (tempArr[i]) {
                  let number = this.getRandImage(this.correctImages)
                  while (alreadyCorrect.includes(number)) {
                    number = this.getRandImage(this.correctImages)
                  }
                  alreadyCorrect.push(number)

                  this.randomImages.push({
                    imgNumber: number,
                    imgCorrectValue: 1,
                    imgPath: '/public/img/test/1/subtest1/' + number + '.jpg'
                  })
                } else {
                  let number = this.getRandImage(this.incorrectImages)
                  while (alreadyIncorrect.includes(number)) {
                    number = this.getRandImage(this.correctImages)
                  }
                  alreadyIncorrect.push(number)

                  this.randomImages.push({
                    imgNumber: number,
                    imgCorrectValue: 0,
                    imgPath: '/public/img/test/1/subtest1/set/' + number + '.jpg'
                  });
                }
              }

            },
            loadImagesBatch(fromIndex, toIndex) {
              return this.randomImages.slice(fromIndex, toIndex)
            },
            showBack() {
                return this.back && this.carouselData > 0;
            },
            showNext() {
                if (this.carouselData === 1) {
                    return false;
                }
                if (this.carouselData < this.carouselItems) {
                    return true;
                } else {
                    this.$store.state.randomImages = this.randomImages;
                    this.$emit('continue', {
                        data: this.userInputs
                    });
                    return false;
                }
            },
            stopTimer(timer) {
                this.showTimers[timer] = false
            },
            slidePrev() {
                this.$emit('dont-continue', {});
                this.$refs.carousel.slidePrev();
            },
            slideNext() {
                this.$refs.carousel.slideNext();
            },
            slideNextFromTimer(timer) {
                if (this.showTimers[timer]) {
                    this.$refs.carousel.slideNext();
                }
            },
            updateCarousel(payload) {
                this.carouselData = payload.currentSlide;
            },
            gatherInputData(payload) {
                this.userInputs.push(payload);
            },

        }
    }
</script>


<style scoped>
  .card-carousel-wrapper .slider-wrapper .image-holder.maxh {
    height: 200px;
    border-radius: 5px;
    box-shadow: 0 0 12px -6px rgba(0, 0, 0, .4);
    margin: 2rem 2rem 0 2rem;
    padding: 2rem;
    overflow: hidden;
  }

  .card-carousel-wrapper .slider-wrapper .image-holder img {
    width: 100%;
    height: 100%;
    max-width: 150px;
    margin: 0 auto;
    object-fit: contain;
  }
</style>
