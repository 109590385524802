import axios from 'axios'
import Cookies from 'js-cookie'
import * as types from '../mutation-types'

// state
export const state = {
  user: null,
  token: Cookies.get('token'),
  accessCode: null,
  testDataId: null,
  firstTaskImages: []
}

// getters
export const getters = {
  user: state => state.user,
  token: state => state.token,
  accessCode: state => state.accessCode,
  testDataId: state => state.testDataId,
  check: state => state.user !== null,
  saveFirstTaskImage: state => state.firstTaskImages
}

// mutations
export const mutations = {
  [types.SAVE_TOKEN] (state, { token, remember }) {
    state.token = token
    Cookies.set('token', token, { expires: remember ? 365 : null })
  },

  [types.SAVE_ACCESS_CODE] (state, { accessCode, testDataId }) {
    state.accessCode = accessCode;
    state.testDataId = testDataId;
  },

  [types.SAVE_FIRST_TASK_IMAGES] (state, { images }) {
    state.firstTaskImages.push(images);
  },

  [types.GET_FIRST_TASK_IMAGES] (state) {
    return state.firstTaskImages;
  },

  [types.GET_ACCESS_CODE] (state) {
    return state.accessCode
  },
  [types.GET_DATA_ID] (state) {
    return state.testDataId
  },

  [types.FETCH_USER_SUCCESS] (state, { user }) {
    state.user = user
  },

  [types.FETCH_USER_FAILURE] (state) {
    state.token = null
    Cookies.remove('token')
  },

  [types.LOGOUT] (state) {
    state.user = null
    state.token = null

    Cookies.remove('token')
  },

  [types.UPDATE_USER] (state, { user }) {
    state.user = user
  }
}

// actions
export const actions = {
  saveToken ({ commit, dispatch }, payload) {
    commit(types.SAVE_TOKEN, payload)
  },

  saveAccessCode ({ commit, dispatch }, payload) {
    commit(types.SAVE_ACCESS_CODE, payload)
  },

  saveFTI ({ commit, dispatch }, payload) {
    commit(types.SAVE_FIRST_TASK_IMAGES, payload)
  },

  getFTI ({ commit }) {
    commit(types.GET_FIRST_TASK_IMAGES)
  },

  getAccessCode ({ commit }) {
    commit(types.GET_ACCESS_CODE)
  },
  getTestDataId ({ commit }) {
    commit(types.GET_DATA_ID)
  },

  async fetchUser ({ commit }) {
    try {
      const { data } = await axios.get('/api/user')

      commit(types.FETCH_USER_SUCCESS, { user: data })
    } catch (e) {
      commit(types.FETCH_USER_FAILURE)
    }
  },

  updateUser ({ commit }, payload) {
    commit(types.UPDATE_USER, payload)
  },

  async logout ({ commit }) {
    try {
      await axios.post('/api/logout')
    } catch (e) { }

    commit(types.LOGOUT)
  },

  async fetchOauthUrl (ctx, { provider }) {
    const { data } = await axios.post(`/api/oauth/${provider}`)

    return data.url
  }
}
