<template>
  <div class="text-center">
    <div class="test-controls">
      <div class="row mb-2">
        <div class="col">
          <button v-if="answered === -1" class="btn btn-primary" @click="answer(0)">Nie</button>
          <button v-if="answered === -1" class="btn btn-primary" @click="answer(1)">Áno</button>
        </div>
      </div>
    </div>
  </div>
</template>



<script>

    export default {

        name: 'TestControlsBool',
        template: "#test-controls-bool",
        props: ['result', 'img'],
        data() {
            return {
                answered: -1
            }
        },
        watch: {
            carouselData() {
                this.$refs.carousel.slideTo(this.carouselData);
            }
        },
        mounted() {
        },
        methods: {
            answer(value){
                if (value === this.result){
                    this.answered = 1; //correctly
                }
                else{
                    this.answered = 0 //incorrectly
                }
                this.$emit('send-input-data', {
                    img: this.img,
                    correctResult: this.result,
                    inputResult: value,
                    timestamp: new Date().toLocaleString(),
                })
            }
        }
    }
</script>
