// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const SAVE_ACCESS_CODE = 'SAVE_ACCESS_CODE'
export const GET_ACCESS_CODE = 'GET_ACCESS_CODE'
export const SAVE_FIRST_TASK_IMAGES = 'SAVE_FIRST_TASK_IMAGES'
export const GET_FIRST_TASK_IMAGES = 'GET_FIRST_TASK_IMAGES'
export const GET_DATA_ID = 'GET_DATA_ID'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'
