<template>
  <div id="app">
    <loading ref="loading"/>

    <vue-particles
      color="#ffffff"
      :particleOpacity="0.7"
      linesColor="#ffffff"
      :particlesNumber="80"
      shapeType="circle"
      :particleSize="5"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="2"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="false"
      clickMode="push"
    >
    </vue-particles>

    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout"/>
    </transition>
  </div>
</template>


<style scoped>
  @import "../../sass/custom/loginBtn.css";
  @import "../../sass/custom/particles.css";
</style>

<script>
    import Loading from './Loading'

    // Load layout components dynamically.
    const requireContext = require.context('~/layouts', false, /.*\.vue$/)

    const layouts = requireContext.keys()
        .map(file =>
            [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
        )
        .reduce((components, [name, component]) => {
            components[name] = component.default || component
            return components
        }, {})

    export default {
        el: '#app',

        components: {
            Loading
        },


        data: () => ({
            layout: null,
            defaultLayout: 'default'
        }),

        metaInfo() {
            const {appName} = window.config

            return {
                title: appName,
                titleTemplate: `%s · ${appName}`
            }
        },

        mounted() {
            this.$loading = this.$refs.loading

            let externalScript = document.createElement('script')
            externalScript.setAttribute('src', 'https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js')
            document.head.appendChild(externalScript)
        },

        methods: {
            /**
             * Set the application layout.
             *
             * @param {String} layout
             */
            setLayout(layout) {
                if (!layout || !layouts[layout]) {
                    layout = this.defaultLayout
                }

                this.layout = layouts[layout]
            }
        }
    }
</script>
